export const colors = {
  opaque: "rgb(0 0 0 / 0.7)",
  lightseagreen: "lightseagreen",
  bisque: "bisque",
  border: "#000"
};

export const fontSize = {
  base: "16px"
};
